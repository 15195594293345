/* Transitions.css */

.patient-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .patient-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1000ms, transform 1000ms; /* Increased duration */
  }
  
  .patient-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .patient-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 200ms, transform 200ms; /* Increased duration */
  }
  