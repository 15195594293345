.app {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}
@media(min-width: 1920px) {
  .app {
    max-width: 1800px;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filters button {
  margin-right: 10px;
  background-color: #c2fbd7;
  border-radius:80px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.filters button:hover {
  background-color: #0056b3;
}

@media(min-width: 1920px) {
  .filters button {
    padding: 12px 24px; /* Further increase for larger screens */
  }
}

.patient-card {
  background-color: #f8f9fa;
  padding: 15px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.patient-card:hover {
  background-color: #e2e6ea;
}

.patient-card p {
  margin: 0 0 10px;
}

.patient-card button {
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.patient-card button:hover {
  background-color: #218838;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

form input,
form select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form button {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

form button:hover {
  background-color: #0056b3;
}

form label {
  display: flex;
  align-items: center;
  gap: 10px;
}

form label span {
  flex-grow: 1;
}



.patient-list {
  display: flex;
  flex-direction: column;
  
}

.patient-card {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}



/* App.css */



/* Style for the scrollable patient list */
.patient-list {

  width: 100%;
  height: 100%;
  overflow-y: auto;
  border: 1px solid #ddd; /* Optional border style */
  padding: 10px; /* Optional padding */
  background-color: #72acf4; /* Optional background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

/* Style for each patient card */
.patient-card {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.patient-card p {
  margin: 5px 0;
}

.patient-card button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.patient-card button:hover {
  background-color: #0056b3;
}

.patient-list-container {
  background-color: #6bade6; /* Light blue color */
  max-height: 600px; /* Adjust height as needed */

  border: 1px solid #ccc;
  padding: 10px;
  margin: 20px 0;
}

.patient-list {
  display: flex;
  flex-direction: column;
}



.call-but-not-ready {
  background-color: yellow; /* Highlight in yellow */
}

.patient-card p {
  cursor: pointer;
}

/* Style for the container that centers the patient list */
.patient-list-container {
  background-color: #6bade6; /* Light blue color */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Adjust height as needed */
  width: 60vw;  /* Adjust width as needed */
  margin: auto;
  overflow: hidden; /* Hide overflow from the container itself */
}

/* Style for the scrollable patient list */
.patient-list {
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ddd; /* Optional border style */
  padding: 10px; /* Optional padding */
  background-color: hsla(0, 8%, 95%, 0.687); /* Light blue color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  max-height: 600px; /* Adjust height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc;
  padding: 10px;
}

/* Style for each patient card */


.call-but-not-ready {
  background-color: yellow; /* Highlight in yellow */
}


/* Add any additional styling here */

.patient-list-container {
  background-color: #6bade6; /* Light blue color */
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.patient-list {
  display: flex;
  flex-direction: column;
}

.patient-card.call-but-not-ready {
  background-color: yellow;
}

button {
  margin: 5px;
}

/* Style for the container that centers the patient list */
.patient-list-container {
  background-color: #6bade6; /* Light blue color */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Adjust height as needed */
  width: 60vw;  /* Adjust width as needed */
  margin: auto;
  overflow: hidden; /* Hide overflow from the container itself */
}

/* Style for the scrollable patient list */

/* Style for each patient card */

.app {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
}



.add-patient-container {
  position: fixed;
  top: 150px;
  right: 17px;
}

.add-patient-button {
  font-size: 24px;
  padding: 10px;
  border: none;
  color: gray;
  border-radius: 50%;
  cursor: pointer;
}

.patient-list-container {
  margin-top: 20px;
  background-color: #6bade6; /* Light blue color */
}


.patient-card.call-but-not-ready {
  background-color: yellow;
}



/* Modal Styles */
.ReactModal__Overlay {
  z-index: 1000;
}

.ReactModal__Content {
  z-index: 1001;
}


.date-picker {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.react-datepicker {
  position: absolute;
  z-index: 9999; /* Ensure it's above other content */
}

.due-soon {
  background-color: rgb(245, 106, 106); /* Background for due soon patients */
}

.has-notes {
  background-color: rgb(244, 244, 104); /* Background for patients with notes in 'Ready to Call' */
}

.call-but-not-ready {
  background-color: #f0ad4e; /* Optional: Background for 'Call but Not Ready' status */
}


/* App.css */



.filters button.active-filter {
  background-color: #0056b3;
  color: #fff;
}

/* Add other existing styles below */







.ReactModal__Content {
  max-width: 800px; /* Adjust width as needed */
  max-height: 80vh; /* Adjust height as needed */
  margin: auto;
  padding: 20px; /* Add padding for better spacing */
}


.ReactModal__Content {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between elements */
}

.patient-list-container {
  background-color: #6bade6; /* Light blue color */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  margin: auto;
  overflow: hidden; /* Hide any overflow */
}



.ReactModal__Content {
  max-width: 800px; /* Adjust width as needed */
  max-height: 80vh; /* Adjust height as needed */
  margin: auto;
  padding: 20px;
  
}

body {
  overflow: hidden; /* Prevent page scrolling */
}

.ReactModal__Overlay {
  z-index: 1000;
  overflow: hidden; /* Prevent scrolling on the overlay */
}

.add-patient-container {
  position: fixed;
  top: 27px; /* Distance from the top */
  right: 90px; /* Distance from the right */
  z-index: 1000; /* Ensure it’s on top */
  /* Adjust the position and size for different screen sizes */
}

.add-patient-button {
  font-size: 20px; /* Increase the font size */
  padding: 5px 35px; /* Increase the padding and set horizontal padding */
  border: none;
  color: white;
  border-radius: 5px; /* Rectangle corners */
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
  transition: background-color 0.3s; /* Smooth transition on hover */
  background-color: rgb(5, 72, 5);
}

/* Media Queries for responsive design */
@media (max-width: 1200px) {
  .add-patient-container {
    right: 60px; /* Adjust right distance for medium screens */
    padding: 3px 25px;
  }
}

@media (max-width: 992px) {
  .add-patient-container {
    right: 25px; /* Adjust right distance for smaller screens */
    padding: 3px 25px;
  }
}

@media (max-width: 768px) {
  .add-patient-container {
    right: 4px; /* Adjust right distance for even smaller screens */
    padding: 3px 20px;
  }
}

@media (max-width: 576px) {
  .add-patient-container {
    right: 5px; /* Adjust right distance for extra small screens */
    bottom: 10px; /* Adjust bottom distance for extra small screens */
  }
}

/* Style for the React Modal */
.ReactModal__Content {
  max-width: 400px; /* Increase the width */
  max-height: 90vh; /* Increase the height, adjust as needed */
  width: 100%; /* Full width within the maximum constraints */
  height: auto; /* Allow auto height adjustment */
  padding: 20px; /* Increase padding for better spacing */
  border-radius: 8px; /* Rounded corners */
  background-color: #a7cfbb; /* Background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow */
  overflow: auto; /* Allow scrolling if content overflows */
}




@media(min-width: 1920px) {
  .ReactModal__Content {
    max-width: 1000px; /* Adjust width for larger screens */
  }
}


/* Style for the container that holds the patient list */
.patient-list-container {
  background-color: #c5e4f584; /* Optional background color */
  max-width: 100%; /* Ensure it fits within the page */
  max-height: 600px; /* Increase height, adjust as needed */
  width: 96%;
/* Full width wihin constraints */ /* Auto height adjustment */
  
  overflow-x: hidden; /* Disable horizontal scrolling */
  border: 1px solid #ddd; /* Border style */
  padding: 15px; /* Padding */
  
  

}

@media(min-width: 1920px) {
  .patient-list-container {
    max-width: 1800px; /* Adjust to fit larger screens */
  }
}

/* Style for the header container */
.header {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #fff; /* Background color for the header */
  border-bottom: 1px solid #ddd; /* Optional: border at the bottom of the header */
  position: relative; /* Ensure relative positioning for absolute child elements */
}

/* Style for the logo */
.logo {
  max-height: 60px; /* Adjust height as needed */
  margin-right: 20px; /* Space between the logo and other content */
}

/* App.css */

/* Container to align logo and buttons */
.top-container {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

/* Style for the logo */
.logo {
  height: 50px; /* Adjust size as needed */
  margin-right: 20px; 
}

/* Style for the buttons and filters */
.filters {
  display: flex;
  gap: 10px; /* Space between buttons */
}







.category {
  margin-right: 10px; /* Space between categories */
}





.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 20px; /* Space between buttons */
  justify-content: flex-end; /* Align buttons to the right */
}

.patient-card button {
  margin: 0; /* Remove margin for buttons */
}

.patient-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative; /* Ensure buttons can be positioned absolutely */
  height: auto; /* Allow auto height adjustment */
  max-height: 80px; /* Adjust height as needed */
  min-height: 30px;
}

.patient-card .category {
  flex: 1; /* Make categories take up available space */
  margin-right: 10px; /* Space between categories */
}


.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 15px; /* Space between buttons */
  margin-top: 10px; /* Space above the buttons */
}

.buttons-container button {
  margin: 0; /* Remove extra margin for side-by-side buttons */
}


/* Style for each patient card */


/* Ensure buttons are aligned correctly */


/* Add padding and border to buttons */


/* Add hover effect to buttons */


.button-33 {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

/* Specific button styles */





/* Button hover styles */


.button-33:hover {
  box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}


/* Increase specificity for each button style */
/* Style for the 'Mark Ready' button */
.patient-card .button-33.button-mark-ready {
  background-color: #d0e0ff; /* Soft blue color */
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, .1) 0 -25px 18px -14px inset, rgba(0, 0, 0, .05) 0 1px 2px, rgba(0, 0, 0, .05) 0 2px 4px, rgba(0, 0, 0, .05) 0 4px 8px, rgba(0, 0, 0, .05) 0 8px 16px, rgba(0, 0, 0, .05) 0 16px 32px;
  color: #003d99; /* Dark blue color */
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 12px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

/* Style for the 'Call Not Ready' button */
.patient-card .button-33.button-call-not-ready {
  background-color: #d0e0ff; /* Soft blue color */
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, .1) 0 -25px 18px -14px inset, rgba(0, 0, 0, .05) 0 1px 2px, rgba(0, 0, 0, .05) 0 2px 4px, rgba(0, 0, 0, .05) 0 4px 8px, rgba(0, 0, 0, .05) 0 8px 16px, rgba(0, 0, 0, .05) 0 16px 32px;
  color: #003d99; /* Dark blue color */
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 12px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

/* Style for the 'Mark Called' button */
.patient-card .button-33.button-mark-called {
  background-color: #d0e0ff; /* Soft blue color */
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, .1) 0 -25px 18px -14px inset, rgba(0, 0, 0, .05) 0 1px 2px, rgba(0, 0, 0, .05) 0 2px 4px, rgba(0, 0, 0, .05) 0 4px 8px, rgba(0, 0, 0, .05) 0 8px 16px, rgba(0, 0, 0, .05) 0 16px 32px;
  color: #003d99; /* Dark blue color */
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 12px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

/* Style for the 'Completed' button */
.patient-card .button-33.button-completed {
  background-color: #d0e0ff; /* Soft blue color */
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, .1) 0 -25px 18px -14px inset, rgba(0, 0, 0, .05) 0 1px 2px, rgba(0, 0, 0, .05) 0 2px 4px, rgba(0, 0, 0, .05) 0 4px 8px, rgba(0, 0, 0, .05) 0 8px 16px, rgba(0, 0, 0, .05) 0 16px 32px;
  color: #003d99; /* Dark blue color */
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 12px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

/* Style for the 'Notes' button */
.patient-card .button-33.button-notes {
  background-color: #d0e0ff; /* Soft blue color */
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, .1) 0 -25px 18px -14px inset, rgba(0, 0, 0, .05) 0 1px 2px, rgba(0, 0, 0, .05) 0 2px 4px, rgba(0, 0, 0, .05) 0 4px 8px, rgba(0, 0, 0, .05) 0 8px 16px, rgba(0, 0, 0, .05) 0 16px 32px;
  color: #003d99; /* Dark blue color */
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 12px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-33 :active{
  background-color: #218838;
}

.patient-card .button-33.button-revert-to-need-ready{
  background-color: #d0e0ff; /* Soft blue color */
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, .1) 0 -25px 18px -14px inset, rgba(0, 0, 0, .05) 0 1px 2px, rgba(0, 0, 0, .05) 0 2px 4px, rgba(0, 0, 0, .05) 0 4px 8px, rgba(0, 0, 0, .05) 0 8px 16px, rgba(0, 0, 0, .05) 0 16px 32px;
  color: #003d99; /* Dark blue color */
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 12px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

/* App.css or your main CSS file */
body {
  background-color: #c5e4f5; /* Optional background color */
  margin: 0;
  font-family: Arial, sans-serif; /* Optional: Choose a font for your application */
}

/* src/PasswordProtection.css */
.password-protection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.password-protection form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.password-protection label {
  margin-bottom: 10px;
}

.password-protection input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.password-protection button {
  padding: 8px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.password-protection button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-top: 10px;
}

.password-protection .button-33.button-submit3{
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-logo {
  width: 150px;
  height: auto;
}


/* App.css */







.buttons-container {
  display: flex;
  flex-direction: row;
}

.status-label {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  border: 5px solid;
  font-size: 12px;
  margin-right: 20px; /* Increased margin */
  text-align: center;
}

.status-label.red {
  border-color: red;
  color: red;
}

.status-label.blue {
  border-color: blue;
  color: blue;
}

.status-label.orange {
  border-color: orange;
  color: orange;
}

.status-label.green {
  border-color: green;
  color: green;
}

.status-label.purple {
  border-color: purple;
  color: purple;
}

.status-label.gray {
  border-color: gray;
  color: gray;
}

/* Add other existing styles below */

@media(min-width: 1920px) {
  .patient-card {
    padding: 25px; /* Further increase padding on larger screens */
  }
}


@media(min-width: 1920px) {
  .add-patient-button {
    font-size: 28px; /* Increase font size for larger screens */
    padding: 18px 36px; /* Increase padding */
  }
}




@media(min-width: 1920px) {
  .buttons-container {
    gap: 15px; /* Increase space between buttons for larger screens */
  }
}


/* App.css */
/* App.css */

.delete-button {
  background: url('../assets/trash-icon.png') no-repeat center center;
  background-size: 16px 16px; /* Smaller size for the icon */
  width: 24px; /* Adjust the width */
  height: 24px; /* Adjust the height */
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; /* No background color */
}

.delete-button:hover {
  background-color: rgb(242, 105, 117); /* Light red background on hover */
}

/* Other styles */

.patient-card:hover {
  background-color: hsla(210, 16%, 90%, 0.749);
}

.patient-card p {
  margin: 0 0 10px;
}

.patient-card button {
  padding: 5px 10px;
  background-color: #f9fcfa;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.patient-card button:hover {
  background-color: #de4747;
}






form label {
  display: flex;
  align-items: center;
  gap: 10px;
}

form label span {
  flex-grow: 1;
}

/* Align categories in the patient cards */
.category {
  margin-right: 10px;
  flex: 1; /* Make categories take up available space */
}

.buttons-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between buttons */
}

.confirmation-modal {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-x: hidden; /* Hide horizontal overflow */
}

.confirmation-modal h2 {
  margin-bottom: 20px;
  font-size: 18px;
}

.confirmation-modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirmation-modal-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirmation-modal-buttons .confirm-button {
  background-color: #28a745;
  color: white;
}

.confirmation-modal-buttons .confirm-button:hover {
  background-color: #218838;
}

.confirmation-modal-buttons .cancel-button {
  background-color: #dc3545;
  color: white;
}

.confirmation-modal-buttons .cancel-button:hover {
  background-color: #c82333;
}


.buttons-container button {
  margin-right: 20px; /* Increase the space between buttons */
}

.buttons-container {
  display: flex;
  gap: 20px; /* Use gap to control the space between buttons */
}

.filters button {
  margin-right: 20px; /* Increase the space between buttons */
}

.filters {
  display: flex;
  gap: 20px; /* You can also use gap to control the space between buttons */
}

.logo {
  margin-right: 30px; /* Increase the space between the logo and the buttons */
}

.top-container {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 30px; /* Increase the gap between the logo and buttons */
}


.status-label {
  flex-basis: 10%; /* Adjust based on the space required for the status label */
  text-align: center; /* Center-align the status text */
  margin-right: 10px;
}

.buttons-container {
  display: flex;
  gap: 10px; /* Adjust space between buttons */
  margin-left: auto;
}

.status-label {
  display: inline-block;
  padding: 3px 15px; /* Adjust padding for height and width */
  border-radius: 20px; /* Maintain oval shape with larger radius */
  border: 1.5px solid;
  font-size: 12px; /* Font size */
  margin-right: 15px; /* Space between label and name */
  text-align: center;
  line-height: 1.2; /* Line height to center text vertically */
}

.modal-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin: 10px 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #45a049;
}

.modal-cancel-button {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  margin: 10px 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.modal-cancel-button:hover {
  background-color: #e53935;
}

.modal-textarea {
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  resize: vertical;
}

.modal-heading {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}


.patient-list-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}


@media(min-width: 1920px) {
  .patient-list-container {
    height: calc(100vh - 100px); /* Adjust height for larger screens */
  }
}

.date-input {
  padding: 5px;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.date-input:focus {
  border-color: #007bff;
  outline: none;
}

.clear-button {
  padding: 5px 10px;
  background-color: #dc766f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}

.clear-button:hover {
  background-color: #ce9090;
}


.green-background {
  background-color:rgba(74, 91, 183, 0.651);
}


.half-blue-half-red {
  background: linear-gradient(to right, rgba(74, 91, 183, 0.651), rgb(245, 106, 106));
}


.sidebar {
  position: absolute;
  top: 100px;
  right: 10px; /* Adjust for left or right positioning */
  width: 200px; /* Adjust width as needed */
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.sidebar h4 {
  margin-bottom: 10px;
  font-weight: bold;
}


.patient-counts-container {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  text-align: left; /* Change to 'right' if you prefer */
  color: #333;
}





